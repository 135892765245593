<template>
  <div class="TranslationContainer">
    <div class="header-section  d-flex justify-content-between ">
      <div
        class=" bg-preload "
        style="height: 50px; width:45%;  border-radius: 14px"
      >
        <div class="into-preload"></div>
      </div>
      <div
        class=" bg-preload  "
        style="height: 50px; width:45%;  border-radius: 14px"
      >
        <div class="into-preload"></div>
      </div>
      
    </div>
    
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.TranslationContainer {
  background: #ffffff;
  padding: 10px 10px;
}
</style>
