import { render, staticRenderFns } from "./QuizTranslation.vue?vue&type=template&id=3f9ac5df&scoped=true&"
import script from "./QuizTranslation.vue?vue&type=script&lang=js&"
export * from "./QuizTranslation.vue?vue&type=script&lang=js&"
import style0 from "./QuizTranslation.vue?vue&type=style&index=0&id=3f9ac5df&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f9ac5df",
  null
  
)

export default component.exports